import React, { useCallback } from 'react';
import { Link, useForm } from '@inertiajs/react';
import Button from '@/components/v2/Button.jsx';
import GuestLayout from '@/components/v2/layouts/GuestLayout.jsx';
import { ReactComponent as ArrowRightIcon } from '@assets/icons/arrow-right.svg';
import Form from '@/components/v2/Form.jsx';

const Login = () => {
    const form = useForm({
        email: '',
        password: '',
        remember: false,
    });

    const handleFormSubmit = useCallback(
        (event) => {
            event.preventDefault();
            form.submit('post', route('login'));
        },
        [form],
    );

    return (
        <>
            <div className='flex grow flex-col justify-between gap-10'>
                <div />

                <div className={'flex flex-col gap-10'}>
                    <div className='flex flex-col gap-5'>
                        <h1 className='text-5xl font-semibold'>Log in op uw account</h1>
                        <p>Welkom terug! Log in om uw dashboard te bekijken en uw investeringen te beheren.</p>
                    </div>

                    <Form form={form} onSubmit={handleFormSubmit}>
                        <Form.Field type={'input'} label={'E-mailadres*'} name={'email'} />

                        <Form.Field type={'password'} label={'Wachtwoord*'} name={'password'} />

                        <div className={'flex items-center justify-between'}>
                            <Form.Field type={'checkbox'} label={'Ingelogd blijven'} name={'remember'} />

                            <Link href={route('password.request')}>
                                <p className={'underline'}>Wachtwoord vergeten?</p>
                            </Link>
                        </div>

                        <Button className='h-16 w-full' onClick={handleFormSubmit} loading={form.processing}>
                            <span>Inloggen</span>
                            <ArrowRightIcon className={'size-6'} />
                        </Button>
                    </Form>

                    <div className='flex flex-col gap-5'>
                        <h2 className='text-3xl font-semibold'>Interesse om te investeren?</h2>

                        <Link href={route('investor.register.index')}>
                            <Button className='h-16 w-full border border-deep-black bg-transparent text-deep-black hover:bg-deep-black hover:text-pure-white'>
                                <span>Vrijblijvend registreren</span>
                                <ArrowRightIcon className={'size-6'} />
                            </Button>
                        </Link>
                    </div>
                </div>

                <div />
            </div>
        </>
    );
};

Login.layout = (children) => <GuestLayout children={children} />;

export default Login;
